import { PrimaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { fonts } from '@rsa-digital/evo-shared-components/theme/MoreThan/fonts';
import styled from 'styled-components';

export const StyledPrimaryCta = styled(PrimaryCta)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(25)};

  ${mediaQuery.tabletPortrait`
  margin-bottom: ${spacing(30)};
  `}

  ${mediaQuery.tabletLandscape`
  margin-bottom: ${spacing(45)};
  `}
`;

export const StyledHeading = styled.h1`
  ${fonts.headingLarge}
  margin: ${spacing(9)} 0 0;
`;

export const Subhead = styled.p`
  ${fonts.paragraphLarge}
  margin-top: ${spacing(3)} 0 0;
`;
